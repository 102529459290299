article {
	box-sizing: border-box;

	h1, h2 {
		text-transform: uppercase;
	}

	p + h1 {
		margin-top: 50px;
	}
}

.hidden {
	display: none;
}

@media @desktop {

	article {
		max-width: 900px;
		margin: 0 auto;
		padding: 35px 20px 0;
		box-sizing: border-box;
		position: relative;

		&.two-column {
			padding-top: 0;
			display: flex;
			min-height: 100%;

			.col {
				flex: 1;
				padding-top: 35px;
				padding-right: 35px;
				min-height: 100%;
			}

			.col + .col {
				padding-right: 0;
				padding-left: 35px;
			}
		}

		.portrait {
			width: 300px;
			margin-right: 30px;
			margin-bottom: 20px;
			float: left;
		}
	}

	h1, h2 {
		font-size: 1.6667em;
		margin-bottom: 35px;
	}

	p {
		line-height: 1.75em;
		margin-bottom: 25px;
	}

}

@media @mobile {

	article {
		padding: 20px;

		&.two-column {
			.col + .col {
				padding: 35px;
			}
		}

		.portrait {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	h1 {
		font-size: 1.2em;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid @color-text;
		text-align: center;
	}

	h2 {
		font-size: 1.2em;
	}

	p {
		line-height: 1.5em;
		margin-bottom: 20px;
	}

}