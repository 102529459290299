.form-group {
	input, select, textarea {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.form-actions {
	margin-top: 10px;
	margin-bottom: 30px;

	.btn { width: 100% }
}

input, select {
	height: 33px;
}

textarea {
	resize: none;
}

input, select, textarea {
	box-sizing: border-box;
	padding: 10px 15px;
	color: #3e3e3e;
	background: #fff;
    border: 0;
    border-radius: 4px;
	outline: none;
	transition: all 250ms ease-out;
	
	&:focus {
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
	}
}

.form-success {
	text-align: center;
}