/**
 * Typography
 * 
 * Font and typography styles
 * ===========================================
 */

@import "fonts.less";

@font-novecento: 'novecento_sans_widelight', helvetica, arial;
@font-opensans: 'open_sanslight', helvetica, arial;

* {
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: @font-opensans;
	font-size: 15px;
	color: @color-text;
}

h1, h2, h3, h4, h5, h6 {
	font-family: @font-novecento;
}

strong {
	font-weight: bold;
}