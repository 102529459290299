body#gallery {
	background: #fff;
}

.menu-show-hide {
	transition: all 250ms;
	opacity: 0;

	&.visible {
		opacity: 1;
	}
}

.gallery-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	top: 80px;
	z-index: 0;

	&.visible {
		z-index: 8000;
	}

	@media @mobile {
		top: 75px;
	}
}

.gallery-menu {
	position: fixed;
	width: 100%;
	top: 50px;
	z-index: 0;
	visibility: hidden;

	&.visible {
		z-index: 8005;
		top: 80px;
		visibility: visible;

		.gallery-links {
			visibility: visible;
		}
	}
}

.gallery-thumbnails-wrapper {
	margin: 0 35px;
	padding-bottom: 35px;
	box-sizing: border-box;
	position: fixed;
	z-index: 8002;
	top: 50px;
	bottom: 0;
	width: 100%;
	opacity: 0;
	transition: all 250ms;
	visibility: hidden;

	&.active {
		z-index: 8002;
		opacity: 1;
		top: 0;
		visibility: visible;
	}

	@media @mobile {
		margin: 0 15px;
	}
}

.gallery-thumbnails {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	margin-top: 149px;
	overflow-y: auto;

	&:before {
		margin-bottom: 35px;
	}

	@media @mobile {
		margin-top: 119px;
		margin-left: -5px;
	}
}

.gallery-links-wrapper {
	border-bottom: 1px solid @color-text;
	padding: 0 20px;

	@media @mobile {
		padding: 0;
	}
}

.gallery-links {
	margin: 0;
	padding: 20px 35px 0 35px;
	visibility: hidden;

	@media @mobile {
		padding: 0 20px;
		text-align: left;

		.title {
			font-size: 1em;
		}
	}

	li {
		display: inline-block;

		+ li {
			margin-left: 25px;
		}
	}

	.title {
		text-transform: uppercase;
	}

	.active {
		border-bottom: 3px solid @color-text;

		.title {
			padding-bottom: 20px;
		}
	}
}

.close-icon {
	width: 50px;
	height: 50px;
}

.thumbnail {
	cursor: pointer;
	filter: grayscale(80%);
	transition: all 250ms;

	&:hover,
	&.active {
		filter: none;
	}
}

#gallery {

	position: fixed;
	width: 100%;

	.content-container {
		max-width: 900px;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			max-height: 100%;
		}
	}

	.gallery-wrapper {
		height: 100%;
	}

	.slider {
		overflow: hidden;
		height: 100%;
		
		.gallery-images {
			width: 100% !important;
			position: relative;
			margin-left: -20px;
			white-space: nowrap;
			margin-left: 0;
			height: 100%;

			.image {
				margin-left: 0;
				box-sizing: border-box;
				height: 100%;
				vertical-align: top;
				display: inline-block;
				overflow: hidden;
			}

			&.prepping { visibility: hidden; }
		}
	}
}

@media @mobile {
	#gallery {

		#body { display: block; }
		.nav-link { display: none; }

		.slider {
			position: fixed;
			width: 100%;
			
			.gallery-images {

				&.animate-in {
					transition: left 250ms ease-out;
				}

				.image {
					width: 100%;
				
					img {
						position: relative;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}

		.menu-overlay {

			.gallery {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0.8);
				display: none;
				padding-top: 75px;
				background-clip: content-box;
				box-sizing: border-box;

				&.animate-in {
					transition: 250ms all ease-in;
					opacity: 0;
					display: block;

					&.active {
						opacity: 1;
					}
				}

				&.animate-out {
					transition: 250ms all ease-in;
					display: block;
					opacity: 0;

					&.active {
						opacity: 1;
					}
				}

				&.active {
					display: block;

					.gallery-link.close { display: block; }
				}
			}
		}

		.gallery-thumbnails {
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;

			.thumbnail {
				background-color: @color-text;
				width: 40px;
				height: 40px;
				margin: 10px;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.gallery-link.menu,
	.gallery-link.close {
		position: absolute;
		z-index: 8005;
		top: 20px;
		margin-left: 50%;
		transform: translateX(-50%);
		left: 0;
		height: 35px;
	}

	.gallery-link.close {
		top: -60px;
	}
}

@media @desktop {
	#gallery {
		#body {
			height: auto;
			padding-top: 35px;
		}

		header {
			z-index: 9000;
		}
		
		.wrapper {
			padding-top: 0;
		}

		.content-container {
			width: 100%;
			max-width: 984px;
			margin: 0 auto;
			.clearfix();
			position: relative;
			text-align: center;

			.gallery-wrapper {
				max-width: 900px;
				display: inline-block;
				position: relative;
			}

			img {
				width: 100%;
			}
		}

		.slider {
			.gallery-images {
				transition: left 750ms ease-out;

				.image {
					width: 900px;
				}

				img {
					width: auto;
				}
			}

		}

		.nav-link {
			text-align: center;
			.vertical-align(absolute);

			&.left {
				left: 0;
			}

			&.right {
				right: 0;
			}
		}

		.arrow-icon {
			height: 50px;
			width: 14px;
			margin: 0 auto;
		}

		.menu-overlay {
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.8);
			position: absolute;
			top: 0;
			z-index: 0;

			opacity: 0;
			transition: 250ms opacity ease-in;

			&.visible {
				opacity: 1;
				z-index: 9000;
			}
		}

		.gallery-link.menu {
			position: relative;
			opacity: 0;
			top: 50px;
			transition: 250ms all ease-in 100ms;

			&.visible {
				opacity: 1;
				top: 0;
			}
		}

		.gallery-icon {
			width: 50px;
			height: 50px;
			.vertical-align(relative);
		}
	}

	.gallery {
		.box-sizing(border-box);
		padding: 45px 0;
		display: none;
		position: relative;

		&.visible {
			opacity: 1;
			top: 0;
		}

		&.animate-in {
			transition: 250ms all ease-in;
			opacity: 0;
			display: block;
			top: 50px;

			&.active {
				opacity: 1;
				top: 0;
			}
		}

		&.animate-out {
			transition: 250ms all ease-in;
			display: block;
			opacity: 0;
			top: 50px;

			&.active {
				opacity: 1;
				top: 0;
			}
		}

		&.active {
			display: block;
		}
	}

	.gallery-links {
		.title {
			font-size: 1.667em;
		}
	}

	.gallery-thumbnails {
		margin-right: -23px;
		.clearfix();

		.thumbnail {
			width: 64px;
			height: 64px;
			background-color: @color-text;
			float: left;
			margin-right: 23px;
			margin-bottom: 23px;
		}
	}

	.gallery-menu-icons {
		position: fixed;
		top: 80px;
		width: 100%;
		max-width: 984px;
		margin-left: 50%;
		transform: translateX(-50%);
		z-index: 8004;
	}

	.gallery-link.close {
		position: absolute;
		left: 0;
		top: 15px;

		.close-icon {
			width: 40px;
			height: 40px;
		}
	}
}

@media @mobile {
	.portrait #gallery {
		.gallery-images .image.portrait img {
			height: 100%;
			width: auto;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.landscape #gallery {

		#body {
			display: block;
		}

		.content-container {
			// height: 100%;
			text-align: center;

			img {
				width: auto;
				height: 100%;
			}

			.slider {
				.image {
					width: 100%;

					img {
						width: auto;
						height: 100%;
					}
				}
			}
		}

		.content-container.fill-landscape {
			// height: auto;

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
