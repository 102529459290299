.btn {
	border-radius: 4px;
	background: @color-accent-dark;
	border: 0;
	height: 40px;
	font-family: @font-opensans;
	font-size: 1em;
	color: #fff;
	outline: none;
	transition: all 250ms ease-out;

	&:hover {
		background: lighten(@color-accent-dark, 10%);
	}
}