#blog {
	article {
		h2 {
			margin-bottom: 20px;
		}

		.date {
			display: block;
			margin-bottom: 10px;
			font-style: italic;
			color: @color-accent-dark;
		}

		a {
			color: #1d89ce;
			padding-bottom: 3px;

			&:hover {
				border-bottom: 1px solid #1d89ce;
			}
		}

		img {
			display: inline-block;
			max-width: 100%;
		}
	}

	.blog-navigation {
		margin-bottom: 30px;
	}
}