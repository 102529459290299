/*
 * Overlay/Modal
 * =======================
 */

.overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
	.box-sizing(border-box);
	-webkit-transform: translateZ(10px);
	z-index: 9990;

	.background {
		position: absolute;
		top: 0;
		left: 0;
		background-color: #000;
		opacity: 0;
		width: 100%;
		height: 100%;
		z-index: 9000;
		.transition(all 0.25s ease-out);
	}

	.overlay-container {
		width: 100%;
		height: 100%;
		background-color: #fff;
		position: relative;
		top: 100px;
		opacity: 0;
		z-index: 9001;
		.box-sizing(border-box);
		padding: 45px 20px 20px 20px;
		text-align: center;
		.transition(all 0.25s ease-out);
	}

	.overlay-content {
		margin-top: 45px;
	}

	&.hidden {
		display: none;
	}

	&.active {
		.background {
			opacity: 0.8;
		}

		.overlay-container {
			opacity: 1;
			top: 0;
		}
	}
}