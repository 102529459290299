@font-face {
    font-family: 'novecento_sans_widelight';
    src: url('../../fonts/novecento_sans_wide_light/Novecentosanswide-Light-webfont.eot');
    src: url('../../fonts/novecento_sans_wide_light/Novecentosanswide-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/novecento_sans_wide_light/Novecentosanswide-Light-webfont.woff2') format('woff2'),
         url('../../fonts/novecento_sans_wide_light/Novecentosanswide-Light-webfont.woff') format('woff'),
         url('../../fonts/novecento_sans_wide_light/Novecentosanswide-Light-webfont.ttf') format('truetype'),
         url('../../fonts/novecento_sans_wide_light/Novecentosanswide-Light-webfont.svg#novecento_sans_widelight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('../../fonts/opensans_light/OpenSans-Light-webfont.eot');
    src: url('../../fonts/opensans_light/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/opensans_light/OpenSans-Light-webfont.woff') format('woff'),
         url('../../fonts/opensans_light/OpenSans-Light-webfont.ttf') format('truetype'),
         url('../../fonts/opensans_light/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

