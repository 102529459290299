body {
	background-color: #3e3e3e;
}

html, body {
	height: 100%;
}

#body {
	width: 100%;
	height: 100%;
	// display: table;

	.wrapper {
		width: 100%;
		height: 100%;
	}

	.logo {
		
		use {
			fill: #fff;
		}
	}
}

#home {
	background: transparent url('../images/bg-home.jpg') no-repeat;
	background-size: cover;
	background-position: 50%;

	#body {
		display: table;
		.animation(home-overlay 0.5s ease-in forwards 0.5s);

		.wrapper {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			padding: 0 30px;
		}
	}

	.logo-title {
		display: none;
	}

	.logo-container {
		text-align: center;
		color: #fff;
		max-width: 700px;
		margin: 0 auto;
		padding: 30px;
		background: rgba(0, 0, 0, 0.3);
		font-size: 1.25em;

		position: relative;
		opacity: 0;
		.animation(home-logo 0.25s ease-out forwards 0.75s);

		.description {
			p:first-child {
				font-style: italic;
				font-size: 0.9em;
			}
		}
	}
}

.keyframes(home-overlay, {
		background: rgba(0, 0, 0, 0);
	},
	{
		background: rgba(0, 0, 0, 0.7);
	}
);

.keyframes(home-logo, {
		opacity: 0;
		top: 50px;
	},
	{
		opacity: 1;
		top: 0;
	}
);

@media @desktop {
	#body .logo {
		width: 400px;
	}
}

@media @mobile {
	#body .logo {
		width: 100%;
		max-width: 280px;
	}

	#body .logo-container {
		padding-top: 10px;
	}
}