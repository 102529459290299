footer {
	background: #3e3e3e;
	color: #fff;
    text-align: center;
    height: 80px;
	margin-top: 60px;

	.container {
		max-width: 900px;
		margin: 0 auto;
		padding: 35px 20px 30px;
		box-sizing: border-box;
		position: relative;
	}
}

#home footer {
	margin-top: 0;
	height: auto;

	@media @desktop {
		position: absolute;
		width: 100%;
		bottom: 0;
		background: transparent;
	}
}