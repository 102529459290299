/**
 * Mixins
 * 
 * Various mixins and helpers
 * ===========================================
 */

/*
 * CSS3 helpers
 *	------------
 */

.box-sizing(@val) {
	box-sizing: @val;
	-webkit-box-sizing: @val;
	-moz-box-sizing: @val;
}

.clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

.transition(@val) {
	transition: @val;
	-webkit-transition: @val;
	-moz-transition: @val;
}

.animation(@val) {
	animation: @val;
	-webkit-animation: @val;
	-moz-animation: @val;
}

// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
.vertical-align(@pos) {
	position: @pos;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.keyframes (@name, @fromRules, @toRules) {
	@-webkit-keyframes ~'@{name}' { 0% { @fromRules(); } 100% { @toRules(); } }
	@keyframes ~'@{name}' { 0% { @fromRules(); } 100% { @toRules(); } }
}
