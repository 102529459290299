.with-hero {
	.hero {
		position: fixed;
		// z-index: 9970;
		width: 100%;
		background-color: #000;
		overflow: hidden;

		.hero-container {
			width: 100%;
		}

		img {
			width: 100%;
			position: relative;
		}
	}

	.content-container {
		width: 100%;
		// height: 100%;
		background-color: #fff;
		position: relative;
		z-index: 9980;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	@media @desktop {
		.hero {
			height: 280px;

			.hero-container {
				min-height: 280px;
			}
		}

		.content-container {
			top: 280px;
		}
	}

	@media @mobile {
		.hero {
			height: 160px;

			.hero-container {
				height: 160px;

				img {
					height: auto;
					width: 100%;
				}
			}
		}

		.content-container {
			top: 160px;
		}
	}
}

// The iPhone has trouble with z-index with fixed position elements, so use a
// 3D tranformation to acheive the same effect.
html.iphone {
	.hero {
		-webkit-transform: translateZ(0);
	}
}