/**
 * Header
 * 
 * This file defines the styles for the header
 * ===========================================
 */

header {
	position: fixed;
	width: 100%;
	z-index: 8000;

	.nav-logo use,
	.nav-icon use {
		fill: #fff;
	}

	.container {
		position: relative;
		margin: 0 auto;
		padding: 0 20px;
		.box-sizing(border-box);
		.clearfix();
	}
}

.nav-main {
	font-family: @font-novecento;
	text-transform: uppercase;

	a {
		text-decoration: none;
		color: inherit;
	}
}

#home header {
	.nav-logo {
		display: none;
	}
}

#gallery header {
	background-color: rgba(255, 255, 255, 0.8);
	svg.icon use {
		fill: @color-text;
	}
}

@media @desktop {

	header {
		border-bottom: 1px solid #fff;
		height: 80px;
		padding: 20px 0;
		.box-sizing(border-box);

		.container {
			max-width: 1024px;
			min-height: 43px;
		}

		.nav-logo {
			width: 40px;
			height: 40px;
		}

		.nav-icon {
			display: none;
		}

		.nav-main {
			float: right;
			font-size: 16px;

			li {
				display: inline-block;
			}

			a {
				border-bottom: none;
				padding-bottom: 16px;
				.transition(all 0.25s);

				&:hover {
					border-bottom: 3px solid #fff;
				}
			}
		}
	}

	.nav-main {
		color: #fff;
		position: absolute;
		bottom: 0;
		right: 0;

		li {
			margin-right: 20px;
		}
	}

	#gallery {
		header {
			position: static;
			border-bottom-color: @color-text;
		}

		.nav-main {
			color: @color-text;

			a:hover {
				border-bottom-color: @color-text; 
			}
		}
	}

}

@media @mobile {

	svg.icon {
		width: 35px;
		height: 35px;

		fill: @color-text;
	}

	header {
		padding: 20px 0;

		.container {
			.clearfix();
		}

		.nav-logo {
			width: 35px;
			height: 35px;
			float: left;
		}

		.nav-icon {
			display: block;
			width: 35px;
			height: 35px;
			float: right;
		}

		.nav-main {
			display: none;
		}
	}

	#home header {
		.nav-icon {
			float: none;
			margin: 0 auto;
		}
	}

	.nav-main {
		font-size: 25px;
		color: @color-text;

		a {
			display: block;
			margin-bottom: 25px;
		}
	}

}